import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, HttpClientModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})

export class HomeComponent implements OnInit, OnDestroy  {

  intervalId1 : any;
  intervalId2 : any;
  counter:number = 0;
  RouterUrl: string = 'http://192.168.188.1';
  YourIP: string = '';
  ServerIP: string = '';
  logMessage: string = '';
  ServerDetails : any;
  intervalId : any;  
  MiniPCBtnLabel: string = 'Admin';
  DesktopPCBtnLabel: string = 'Plex';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {}

  //Init
  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) 
    {
      this.getYourIP();
      this.getServerIP();    
      this.fetchServerDetails();  

      //SERVER
      //this.intervalId1 = setInterval(() => { this.checkServerStatus('http://192.168.188.94/api');  }, 5000); 

      //DESKTOP
      //this.intervalId2 = setInterval(() => { this.checkDesktopStatus('http://192.168.188.100/default.htm'); }, 6000); 
    }
  }

  
  //Destroy
  ngOnDestroy(): void {
    /*
    try{
      if (this.intervalId1) {
        clearInterval(this.intervalId1);
      }

      if (this.intervalId2) {
        clearInterval(this.intervalId2);
      }
    }catch(error){}
    */
  }


  //YourIP
  getYourIP(): void {
    this.http.get('https://api.bigdatacloud.net/data/client-ip').subscribe(
      (data: any) => {
        this.YourIP = data.ipString;
        this.RouterUrl = 'http://192.168.188.1';
      }
       ,
       error => console.error(error)
    
    );
  }


  //RouterIP
  getServerIP(): void {
    this.http.get('https://api.bigdatacloud.net/data/client-ip').subscribe(
      (data: any) => this.ServerIP = data.ipString,
      error => console.error(error)
    );
  }


  //CPU + Memory
  fetchServerDetails() {    
    try {
      this.http.get('/tools-api/server_details').subscribe(data => {
        this.ServerDetails = data;
      });
    }catch(error) {
      //console.error(error)
    }    
  }

/*
  //Check MINI-PC
  async checkServerStatus(endpoint: string): Promise<void> {
    if (this.counter > 20) return;
    this.counter++;

    this.MiniPCBtnLabel = 'off';
    try 
    {        
      const options = {method: 'GET', headers: {'Content-Type': 'text/plain'}};
      const response = await fetch(endpoint, options);
      const contentType = response.headers.get("content-type");
      if (response.ok) 
      {          
        const status = await response.text();
        this.MiniPCBtnLabel = 'Admin';          
      }
    } 
    catch  
    {        
    }
}


//Check MINIS
async checkDesktopStatus(endpoint: string): Promise<void> {
  if (this.counter > 20) return;
  this.counter++;
  this.DesktopPCBtnLabel = 'off';    

  try 
  {    
    const options = { method: 'GET',  headers: { 'Content-Type': 'text/html'}};
    const response = await fetch(endpoint, options);
    const contentType = response.headers.get("content-type");
    if (response.ok) 
    {
      const status = await response.text();
      this.DesktopPCBtnLabel = 'Plex';
    }
  } 
  catch 
  {
  }
}
*/

//LogMessages
updateLogMessage(e: string): void {
    this.logMessage = e;
  }
}

import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HomepageComponent } from './homepage/homepage.component';
import { RadioComponent } from './radio/radio.component';
import { G5alarmComponent } from './g5alarm/g5alarm.component';

G5alarmComponent

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'g5alarm', component: G5alarmComponent },
  { path: 'homepage', component: HomepageComponent },
  { path: 'radio', component: RadioComponent },  
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  //{ path: '**', component: HomepageComponent }
];

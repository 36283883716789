    <div id="g5home">

        <div class='flex'>

                <button id="disarm" style="background-color:#966" class="col-12 col-md-4 btn">Απενεργοποίηση</button><br /><br />
		<button id="arm" class="col-12 col-md-4 btn btn2">Ενεργοποίηση</button><br /><br />
		<button id="homemode" class="col-12 col-md-4 btn btn2">Home Mode</button><br /><br />


                <h4>Ρυθμίσεις συναγερμού </h4>
		<button id="disarm" style="background-color:#966" class="col-12 col-md-4 btn btn2">Ανάγνωση ρυθμίσεων</button><br /><br />


                
                    <select id="delayentry" data-shadow="false" style="border:0 !important;">
                        <option value="">Καθυστέρηση εισόδου/εξόδου</option>
                        <option value="0">Χωρίς καθυστέρηση</option>
                        <option value="5">5 sec</option>
                        <option value="10">10 sec</option>
                        <option value="15">15 sec</option>
                        <option value="20">20 sec</option>
                        <option value="30">30 sec</option>
                        <option value="60">60 sec</option>
                    </select>
                
        <br /><br />        
                    <select id="delaysingle" data-shadow="false" style="border:0 !important">
                        <option value="">Καθυστέρηση πόρτας εισόδου</option>
                        <option value="0">Χωρίς καθυστέρηση</option>
                        <option value="5">5 sec</option>
                        <option value="10">10 sec</option>
                        <option value="15">15 sec</option>
                        <option value="20">20 sec</option>
                        <option value="30">30 sec</option>
                        <option value="60">60 sec</option>
                    </select>
	                <br /><br />
                
                    <select id="alarm" style="border:0 !important">
                        <option value="">Εσωτερική σειρήνα</option>
                        <option value="0">Βουβή</option>
                        <option value="11">Χαμηλή ένταση, 1 λεπτό</option>
                        <option value="12">Χαμηλή ένταση, 2 λεπτά</option>
                        <option value="13">Χαμηλή ένταση, 3 λεπτά</option>
                        <option value="14">Χαμηλή ένταση, 4 λεπτά</option>
                        <option value="15">Χαμηλή ένταση, 5 λεπτά</option>
                        <option value="16">Χαμηλή ένταση, 6 λεπτά</option>
                        <option value="17">Χαμηλή ένταση, 7 λεπτά</option>
                        <option value="18">Χαμηλή ένταση, 8 λεπτά</option>
                        <option value="19">Χαμηλή ένταση, 9 λεπτά</option>

                        <option value="21">Υψηλή ένταση, 1 λεπτό</option>
                        <option value="22">Υψηλή ένταση, 2 λεπτά</option>
                        <option value="23">Υψηλή ένταση, 3 λεπτά</option>
                        <option value="24">Υψηλή ένταση, 4 λεπτά</option>
                        <option value="25">Υψηλή ένταση, 5 λεπτά</option>
                        <option value="26">Υψηλή ένταση, 6 λεπτά</option>
                        <option value="27">Υψηλή ένταση, 7 λεπτά</option>
                        <option value="28">Υψηλή ένταση, 8 λεπτά</option>
                        <option value="29">Υψηλή ένταση, 9 λεπτά</option>
                    </select>
                
        </div>
    </div>


<!-- src/app/home/home.component.html -->
<span class='Homepage'>
    <div style="height: 10px;"></div>
    <header>
      <div class="head-section layout">        
  
        <div class="nav-bar flex space-between">
            <div class="logo uppercase f-w-700 letter-spacing2" style="cursor: pointer; padding-bottom: 18px">
                <span>Dimitris</span> Konios
            </div>
            <div class="nav-links flex align-center">
            <ul class="flex f-w-600" style="flex-wrap: wrap">
                <li><a id='HA-Link' href="http://home.konios.gr:8123" title="http://home.konios.gr:8123">ΗΑ</a></li>
                <li><a id="RouterLink" [href]="RouterUrl" [title]="RouterUrl">Router</a></li>
                <li><a href="https://my.dnhost.gr/domains/213845">DNS</a></li>
                <li><a href="https://dash.cloudflare.com/">Cloudflare</a></li>
                <li><a href="https://app.plex.tv/desktop">Plex</a></li>
                <li><a href="http://home.konios.gr:7878/">Radarr</a></li>
                <li><a href="http://home.konios.gr:8989/">Sonarr</a></li>
                <li><a href="http://home.konios.gr:8123/hassio/ingress/db21ed7f_qbittorrent">Torrents</a></li>
                <li><a href="/g5alarm">Συναγερμός</a></li>                
            </ul>
            </div>        
        </div>  

        <div class="content flex">
          <div class="left flex2">
            <h4 class="uppercase letter-spacing2">HOME<span> ASSISTANT</span> </h4>
            <h2 class="my-t-10 uppercase letter-spacing2 f-w-700">Web & Application Server</h2>
            
              <br />
              <span class="my-t-10" style="color: white;">
                <a class='IPSLnk' href="https://green.home-assistant.io/">
                  <span>Home Assistant Green Server</span>
                </a> 
                Open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server.
              </span>
              <br />
              <br />
                <div style="font-size: small;" *ngIf="ServerDetails">
                  <p>CPU Usage: {{ ServerDetails.cpuUsage }}</p>
                  <p>Free Memory: {{ ServerDetails.freeMemory }}</p>
                  <p>Used Memory: {{ ServerDetails.usedMemory }}</p>
                  <p>Total Memory: {{ ServerDetails.totalMemory }}</p>
                </div>              
              
              <br />
              <div class="row col-12 my-t-22" style="gap:10px">   
                <button class="col-12 col-md-4 btn btn2" title="Nakis" style="background-color:#966" onClick="javascript:window.location.href='http://nakis.konios.gr'" >😡 Nakis</button>               
                <button class="col-12 col-md-4 btn btn2" title="Ράδιο" onClick="javascript:window.location.href='/radio'" >♫ Ράδιο</button>
                <button class="col-12 col-md-4 btn btn2" title="HA NGINX" onClick="javascript:window.location.href='http://home.konios.gr:8124'" >≈ NGinX</button>
                <button class="col-12 col-md-4 btn btn2" title="PhpMyAdmin" onClick="javascript:window.location.href='http://home.konios.gr:8123/a0d7b954_phpmyadmin/ingress'" >▦ MySQL</button>
                 
              </div>
              <br />              
              <br />

              <span class="my-t-10" style="color: white;">
                <a class='IPSLnk' href="https://www.amazon.co.uk/dp/B07T3FTM4R?psc=1&ref=ppx_yo2ov_dt_b_product_details">
                  <span>DroiX CK2 Windows NUC Mini PC</span>
                </a> 
                Core i7-7500U, HD Graphics 620, 16GB RAM 512GB Storage, 2.5" HDD/SSD Bay, Wi-Fi, Ethernet, BT, HTPC.
              </span>
            
              <br />
              <br />
              <div class="row col-12 my-t-22" style="gap:10px">                
                  <button class="col-12 col-md-4 btn btn2" title="MINI-PC Administration" onClick="javascript:window.location.href='http://mini-pc.konios.gr/home'" >{{ MiniPCBtnLabel }}</button>
              </div>
              <br />
              <br />             

              <span class="my-t-10" style="color: white;">
                <a class='IPSLnk' href="https://store.minisforum.com/products/elitemini-hx90g">
                  <span>HX99G Desktop Mini PC</span>
                </a> 
                AMD Ryzen 9 6900HX 32GB DDR5 1TB SSD AMD Radeon RX 6600M Mini Gaming PC, 2x HDMI,
                2x USB4 Port, 2x Slot PCIe/Sata SSD, 5x USB3.2 Port, Wi-Fi 6E/BT5. 2. Windows. ws 11 Pro.
              </span>
            
            <br />
            <br />
            <div class="row col-12 my-t-22" style="gap:10px">                
                <button class="col-12 col-md-4 btn btn2" title="Plex" onClick="javascript:window.location.href='https://app.plex.tv/desktop'" >{{ DesktopPCBtnLabel }}</button>
            </div>
            <br />

            <div id="logMessage">{{logMessage}}</div>
          </div>
        
          <div class="right" style="padding-left:20px;align-self: flex-start;">
            <a href="/home">
              <img src="/me.png" />
            </a>
          </div>        

        </div>
      </div>
    </header>
    <footer>
      <div class="footer-sec layout text-center">
        <p>Copyright &copy; Dimitris Konios | All Right Reserved</p>
      </div>
    </footer>
  </span>
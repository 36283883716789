import { Component } from '@angular/core';

@Component({
  selector: 'app-g5alarm',
  standalone: true,
  imports: [],
  templateUrl: './g5alarm.component.html',
  styleUrl: './g5alarm.component.less'
})
export class G5alarmComponent {

}
